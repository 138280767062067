@supports (-moz-appearance:none) {
    @font-face {
        font-family: "retropix"!important;

        src:    local("retropix"),
                url(./Assets/fonts/retropix.eot) format('embedded-opentype'),
                url(./Assets/fonts/retropix.otf) format('opentype'),
                url(./Assets/fonts/retropix.svg) format('svg'),
                url(./Assets/fonts/retropix.ttf) format('truetype'),
                url(./Assets/fonts/retropix.woff) format('woff'),
                url(./Assets/fonts/retropix.woff2) format('woff2')
                ;

        font-weight: 400;
        /* font-style: normal!important; */
        /* font-size: 16px; */
    }
    
}

@supports (not (-moz-appearance:none)) {
    @font-face {
        font-family: "retropix";
    
        src:    local("retropix"),
                url(./Assets/fonts/retropix.eot) format('embedded-opentype'),
                url(./Assets/fonts/retropix.otf) format('opentype'),
                url(./Assets/fonts/retropix.svg) format('svg'),
                url(./Assets/fonts/retropix.ttf) format('truetype'),
                url(./Assets/fonts/retropix.woff) format('woff'),
                url(./Assets/fonts/retropix.woff2) format('woff2')
                ;
    
        font-weight: 400;
        /* font-style: normal!important; */
        /* font-size: 16px; */
    }
}

